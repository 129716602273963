import { graphql } from "gatsby"
import React from "react"
import Cards from "../components/Cards"
import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"
import Features from "../components/Features"

const IndexPage = ({ data }) => {
  console.log(data)
  return (
    <Layout
      headerImg={data.header.childImageSharp.fluid}
      footerImg={data.tourism.childImageSharp.fluid}
    >
      <SiteMetadata title="Home" description="Portfolio of John Doe" />

      {/* <Hero /> */}
      <Features img={data.author.childImageSharp.fluid} />

      <div className="bg-gray-100 py-12 lg:py-16">
        {data.portfolio && data.portfolio.nodes.length > 0 ? (
          <Cards items={data.portfolio.nodes} />
        ) : (
          <div className="container">No projects found.</div>
        )}
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    author: file(relativePath: { eq: "stall.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 480, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    header: file(relativePath: { eq: "icon.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 600, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    tourism: file(relativePath: { eq: "tourism-logo.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 480, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    portfolio: allContentfulPost {
      nodes {
        ...PostCard
      }
    }
  }
`
