/* This example requires Tailwind CSS v2.0+ */
import {
  BadgeCheckIcon
} from '@heroicons/react/outline'
import Img from "gatsby-image"

import React from 'react'

const features = [
  { name: 'Adventure Advice', icon: BadgeCheckIcon, description: 'Our team is looking forward to helping make your Centralian experience memorable. Whether you are flying solo, mustering a group or new to town, we have a cool network of great providers ready to guide and inspire you towards living your best life.' },
  { name: 'Appointment Assistance', icon: BadgeCheckIcon, description: `We understand the importance of health and wellbeing. Simply schedule your requirements with our team and we'll get you there and back without fuss.` },
  { name: 'Child Care', icon: BadgeCheckIcon, description: 'Professional baby and child care is available for your Date Night, Conference and Holiday needs. We love little people. Talk to us today.' },
  { name: 'Community Engagement', icon: BadgeCheckIcon, description: 'Scheduling Community Supports provide you with an opportunity to enjoy one on one experience with a Hello Alice companion or you may choose to join a group to enjoy the local markets, events and anything else that suits your requirement.' },
  { name: 'Companion Shopping', icon: BadgeCheckIcon, description: 'Whether you seek a day out with one of our shopping companions or you would like your errands and shopping done on your behalf, our helping hands will deliver.' },
  { name: 'Event Planning', icon: BadgeCheckIcon, description: 'To create great memories, we deliver bespoke opportunities and have a sound network of experienced supporters to bring your event to life.' },
  { name: 'Health and Wellbeing', icon: BadgeCheckIcon, description: 'Whether you need nutritional advice, momentum for gym attendance or advice on creating a better sense of wellbeing, we are here to help. We love guiding you to some of the great activities and sports clubs available to you in Alice Springs.' },
  { name: 'Household Services', icon: BadgeCheckIcon, description: 'Hello Alice provides family and child care, house sitting opportunities, cooking, decluttering, horticulture and gardening services, Spring cleaning, streamlining and styling and much more.' },
  { name: 'Interpret and Translate', icon: BadgeCheckIcon, description: 'Schedule a meeting with our qualified AUSLAN Interpreter, available to individuals with culturally and linguistically diverse  backgrounds. Hello Alice, helping you live your best life.' },
  { name: 'NDIS Participants', icon: BadgeCheckIcon, description: 'We primarily work with Plan Managed families on a one-to-one basis however, we love to help everyone under the NDIS umbrella to ensure they can make the most of their Plan and more importantly, to develop their life skills for solid integration into the community for their best life.' },
  { name: 'Life Coaching', icon: BadgeCheckIcon, description: ' Are you where you want to be? Why not schedule a chat to see how you might achieve your goals.' },
  { name: 'Pet Care', icon: BadgeCheckIcon, description: 'Being part of the family is an institution of love. We walk, sit and care for your pets as if they are our own, most often in their own home.' },
]

export default function Features({ img }) {
  return (
<div className="bg-gradient-to-r from-orange-800 to-orange-700">
          <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8">
            <h2 className="text-3xl font-extrabold text-white tracking-tight">Support Services for Territorians and Visitors.</h2>
            <p className="mt-4 max-w-3xl text-lg text-purple-200">
            Our professional helping hands help you manage life's essentials.  Whether you are flying solo, a busy family or visiting Central Australia, our team will coordinate with our trusted network to deliver the best outcomes for peace of mind. We love what we do!
            </p>
            <div className="w-full p md:w-3/4 pt-10">
            <Img
              fluid={img}
              alt="Michelle with man at stall"
              className="rounded-md shadow-md"
            />
          </div>
            <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
              {features.map((feature) => (
                <div key={feature.name}>
                  <div>
                    <span className="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10">
                      <feature.icon className="h-6 w-6 text-gray-900" aria-hidden="true" />
                    </span>
                  </div>
                  <div className="mt-6">
                    <h3 className="text-lg font-medium text-white">{feature.name}</h3>
                    <p className="mt-2 text-base text-purple-200">{feature.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
  )
}
